// SECTION TITLE
.section-title {
    margin-bottom: 80px;
    font-family: 'Exo', sans-serif;

    .pre-title {
        text-transform: uppercase;
        letter-spacing: .05em;
        font-weight: 700;
        margin-bottom: 6px;
        background: linear-gradient(90.41deg, #15D6B9 -1.5%, #10A9E5 99.65%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: inline-block;
    }

    .main-title {
        font-size: 53px;
        font-weight: 600;
        font-weight: 700;
        @media screen and (max-width: 768px) {
            font-size: 36px;
        }
    }

    .sub-title {
        margin-top: 12px;
        color: #A0A0AA;
        font-weight: 500;
        font-size: 18px;
        width: 75%;
        &.text-lg {
            font-size: 28px;
        }
    }

    &.text-center {
        .sub-title {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.text-end {
        .sub-title {
            margin-left: auto;
        }
    }

    &.mb {
        &-sm {
            margin-bottom: 40px;
        }

        &-md {
            margin-bottom: 60px;
        }

        &-lg {
            margin-bottom: 100px;
        }

        &-xl {
            margin-bottom: 120px;
        }
    }
}