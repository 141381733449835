.theme-cta {
    background: linear-gradient(90.41deg, #15D6B9 -1.5%, #10A9E5 99.65%);
    border-radius: 16px;
    color: #fff;
    padding: 80px 145px;
    @media screen and (max-width: 767px) {
        padding: 80px 50px;
    }
    @media screen and (max-width: 575px) {
        padding: 60px 30px;
    }
    .section-title {
        .main-title, .sub-title {
            color: #0F1428;
        }
        .sub-title {
            font-size: 23px;
            font-weight: 600;
        }
    }
}