// Navbar
@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: auto;
    }
}
:root {
    --bs-font-sans-serif: 'Montserrat', sans-serif;
    --bs-body-bg: #060708;
    --bs-body-color: #fff;
    --bs-border-width: 0;
}
body {
    overflow-x: hidden;
    font-weight: 500;
}

.navbar {
    --bs-navbar-padding-y: 40px;
    --bs-navbar-padding-x: 0px;
    --bs-navbar-toggler-focus-width: 0;
    --bs-navbar-nav-link-padding-x: 22px;
    --bs-navbar-nav-link-padding-y: 6px;
    --bs-navbar-active-color: #fff;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 15%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");

    .navbar-brand {
        max-width: 260px;
        @media screen and (max-width: 575px) {
            max-width: 220px;
        }
        img {
            transition: .25s linear;
            width: 260px;
        }
    }
    @media screen and (max-width: 575px) {
        .navbar-brand {
            max-width: 180px;
        }
    }
    @media screen and (max-width: 992px) {
        .navbar-collapse {
            margin-top: 20px;
            padding: 20px;
            background-color: #111424;
        }
    }
}
.navbar-nav {
    a {
        color: #FFFFFF;
        opacity: 0.8;
        transition: .25s linear;
        &:hover {
            opacity: 1;
        }
        &.active {
            opacity: 1;
        }
        @media screen and (max-width: 992px) {
            padding: 10px 10px;
        }
    }
}

.navbar .form-select {
    padding: 10px;
    padding-right: 0px;
    background-color: transparent;
    color: #fff;
    border: none;
    &:focus-visible {
        border: none;
        outline: none;
    }
}
.navbar .form-select option {
    background-color: var(--bs-body-bg);
}

// Button
.btn {
    font-weight: 600;
    transition: .25s linear;
    &:hover {
        transform: translateY(-4px);
    }
    &-primary {
        background-image: linear-gradient(90.41deg, #15D6B9 -1.5%, #10A9E5 99.65%);
    }
}

// Font Weight
.fw-extrabold {
    font-weight: 800;
}


// Accordion
.accordion {
    --bs-accordion-btn-bg: transparent;
    --bs-accordion-active-bg: transparent;
    --bs-accordion-active-color: #fff;
}
.accordion-button {
    display: flex;
    flex-direction: row-reverse;
    @extend .justify-content-end;
    @extend .align-items-start;
    gap: 16px;
    font-weight: 600;
    font-size: 23px;
    line-height: 150%;
    font-family: 'Exo', sans-serif;
    &:after {
        margin-left: 0;
        margin-top: 5px;
    }
    &:focus {
        box-shadow: none;
    }
}
.accordion-body {
    margin-left: 80px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 32px;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #C0C0CD;
    margin-top: 22px;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
        margin-left: 0px;
        font-size: 16px;
    }
}