.card-wrap {
    height: 100%;
    padding-bottom: 54px;
}

.card-how {
    margin-top: 54px;
    padding: 0 26px 40px 26px;
    border-radius: 16px;
    background-color: #131B1F;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 1;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 100%;
        border-radius: inherit;
        background-image: url('../../img/how-blur.svg');
        background-size: cover;
        background-position: bottom center;
        z-index: -1;
    }
    .card {
        &-image {
            transform: translateY(-54px);
            height: 158px;
        }
        &-title {
            font-family: 'Exo', sans-serif;
            font-weight: 600;
            font-size: 27px;
            line-height: 150%;
            margin-bottom: 16px;
            margin-top: -54px;
        }
        &-text {
            color: #C0C0CD;
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: -54px;
        }
    }
}

.card-feature {
    display: flex;
    align-items: center;
    gap: 24px;
    height: 100%;
    .feature-content {
        .feature-title {
            font-family: 'Exo', sans-serif;
            font-weight: 600;
            font-size: 27px;
            line-height: 150%;
            margin-bottom: 8px;
        }
        .feature-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #C0C0CD;
        }
    }
}

.card-roadmap {
    background: linear-gradient(112.22deg, rgba(255, 255, 255, 0.08) 10.24%, rgba(18, 21, 47, 0.0664) 94.31%);
    backdrop-filter: blur(18px);
    border-radius: 16px;
    padding: 24px 32px;
    padding-bottom: 18px;
    margin-top: 32px;
    height: 100%;
    @media screen and (max-width: 1199px) {
        margin-top: 0;
        padding-bottom: 32px;
    }
    .card-head {
        display: flex;
        align-items: center;
        gap: 20px;
        font-family: 'Exo' , sans-serif;
        margin-bottom: 24px;
        .qs {
            font-weight: 600;
            font-size: 34px;
            line-height: 140%;
        }
        .year {
            font-weight: 500;
            font-size: 22px;
            line-height: 150%;
        }
        .line {
            background-image: linear-gradient(90.41deg, #15D6B9 -1.5%, #10A9E5 99.65%);
            height: 1px;
            width: 100%;
        }
    }
    .card-content {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: flex;
                gap: 12px;
                @extend .align-items-start;
                font-size: 18px;
                line-height: 150%;
                color: #C0C0CD;
                font-weight: 400;
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
                &:before {
                    content: url('../../img/roadmap-dot.svg');
                    margin-top: 2px;
                }
            }
        }
    }
}