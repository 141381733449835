.theme-footer {
    padding-top: 70px;
}
.footer {
    &-top {
        margin-bottom: 40px;
    }
    &-content {
        p {
            color: #FCFCFC;
        }
    }
    &-bottom {
        padding-top: 48px;
        padding-bottom: 85px;
        @media screen and (max-width: 992px) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
    &-copyright {
        color: #8E9095;
        font-size: 14px;
    }
    &-widget {
        margin-bottom: 40px;
        .widget-title {
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 1px;
            color: #FCFCFC;
        }
        ul {
            @extend .list-unstyled;
            @extend .d-flex;
            @extend .flex-column;
            @extend .gap-2;
            li a {
                color: #8E9095;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}

.social-links {
    display: flex;
    gap: 12px;
    a {
        width: 48px;
        height: 48px;
        font-size: 20px;
        border-radius: 999px;
        background-color: #181C1F;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        &:hover {
            @extend .bg-primary;
            color: #fff;
        }
        svg {
            font-size: 20px;
            width: 20px;
        }
    }
}