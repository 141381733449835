.icon-square {
    height: 75px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    flex-shrink: 0;

    &.icon-circle {
        border-radius: 999px;
    }

    &.icon-dark {
        background-color: #101315;
        position: relative;
        z-index: 0;
        overflow: hidden;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: inherit;
            background: linear-gradient(90deg, #0996D2 -1.74%, #6B0FE0 100%);
            filter: blur(18px);
            z-index: -1;
            opacity: .25;
        }
    }
}